<template>
  <div class="dashboard">
    <dashboard-stickers />
    <dashboard-bookmarks />
  </div>
</template>

<script>
import DashboardStickers from '@/modules/Stikers/DashboardStickers'
import DashboardBookmarks from '@/modules/Bookmarks/DashboardBookmarks'

export default {
  name: 'Index',
  components: {
    DashboardStickers,
    DashboardBookmarks
  }
}
</script>

<style scoped>

</style>

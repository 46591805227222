<template>
  <div class="sticker">
    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <h2>Sticker</h2>
    <input v-model="form.title" ref="autofocusInput" class="sticker__title" placeholder="Title" type="text" autofocus>

    <div class="sticker__editor">
      <quill-editor
          v-model="form.body"
          :options="editorOption"
          :style="{ color: form.color, background: form.bgColor }"
      ></quill-editor>
    </div>

    <div class="sticker-colors">
      <label class="sticker-colors__label">
        <input v-model="form.bgColor" class="sticker-colors__color" type="color">
        <span>Background</span>
      </label>
      <label class="sticker-colors__label">
        <input v-model="form.color" class="sticker-colors__color" type="color">
        <span>Text</span>
      </label>
    </div>

    <div class="footer-buttons">
      <button class="footer-buttons__btn -close" @click="close" @keypress.enter="close" @keypress.space="close">Close</button>
      <button class="footer-buttons__btn -save" @click="onSaveSticker" @keypress.enter="onSaveSticker" @keypress.space="onSaveSticker">Save</button>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Loading from 'vue-loading-overlay'
import { mapGetters } from 'vuex'
import DefaultSticker from './Model/Sticker'

export default {
  name: 'StickerForm',
  components: {
    quillEditor,
    Loading
  },
  props: {
    sticker: {
      type: Object,
      default: () => { return { ...DefaultSticker } }
    },
    saveSticker: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      form: {
        title: '',
        body: '',
        color: '#111111',
        bgColor: '#ffffff80'
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean'],
            ['link']
          ],
          clipboard: {
            matchVisual: false
          }
        },
        history: {
          delay: 2000,
          maxStack: 100,
          userOnly: true
        }
      }
    }
  },
  mounted() {
    this.form = this.form = (({ title, body, color, bgColor }) => ({ title, body, color, bgColor }))(this.sticker)
    this.$nextTick(()=>{
      this.$refs.autofocusInput.focus()
    })
  },
  computed: {
    ...mapGetters('Stickers', ['isLoading'])
  },
  methods: {
    onSaveSticker () {
      const sticker = { ...this.sticker, ...this.form }

      this.saveSticker(sticker)
        .then(() => {
          this.close()
        })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.sticker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 316px;
  margin: 0 auto;

  &__title {
    display: block;
    width: 100%;
    border: none;
    margin: 15% 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 20px;
    color: #fff;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #ddd;
    }
  }

  &__editor::v-deep {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .ql-toolbar {
      background: rgba(255, 255, 255, .7)!important;
    }

    .quill-editor {
      width: 100%;

      ol {
        padding-left: 10px;
      }
      ul {
        padding-left: 10px;
      }
    }
    .ql-editor {
      width: 100%;
      height: 168px;
      padding: 10px 8px;
      background: inherit;
      color: inherit;
    }
  }
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  &__btn {
    padding: 5px 30px;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, .5);

    &.-close {
      background: rgba(255, 0, 100, .5);

      &:hover {
        background: darken(rgba(255, 0, 100, .5), 15%);
      }
    }

    &.-save {
      background: rgba(0, 255, 100,.5);

      &:hover {
        background: darken(rgba(0, 255, 100, .5), 15%);
      }
    }
  }
}

.sticker-colors {
  display: flex;
  width: 100%;
  justify-content: space-around;

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__color {
    margin-top: 15px;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 50px;
    padding: 5px;
  }
}

.editor-menu {
  display: flex;
  align-items: center;
  padding: 0 5px;
  background: rgba(255, 255, 255, 0.70);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;

  &__button {
    background: none;
    border: none;
    outline: none;
    height: 30px;
    padding: 0 10px;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>

<style lang="scss">
.sticker-editor {
  width: 100%;
  height: 168px;
  background: rgba(255, 255, 255, 0.5);
  color: #111;
  overflow-y: auto;


  .ProseMirror {
    width: 100%;
    min-height: 100%;
    padding: 3px;
  }
}
</style>

<template>
  <div class="sticker" :style="{ background: sticker.bgColor, color: sticker.color }">
    <div class="sticker__control">
      <div class="control-group">
        <span class="sticker__control__star" :class="{ active: sticker.onDashboard }" @click="onDashboard(sticker)"><i
            class="fa fa-star"></i></span>
        <span class="sticker__control__edit" @click="openStickerForm"><i class="fas fa-cog"></i></span>
      </div>
      <span class="sticker__control__remove" @click="deleteSticker(sticker)"><i class="fa fa-times"></i></span>
    </div>
    <div class="sticker__title" v-if="sticker.title.length > 0" :title="sticker.title">{{ sticker.title }}</div>

    <div class="sticker__body" v-html="sticker.body" ref="stickerBody"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import StickerForm from './StickerForm'
import DefaultSticker from './Model/Sticker'

export default {
  name: 'Sticker',
  props: {
    sticker: {
      type: Object,
      default: () => { return { ...DefaultSticker } }
    }
  },
  mounted () {
    const stickerBody = this.$refs.stickerBody

    stickerBody.addEventListener('mouseup', () => {
      const selection = window.getSelection().toString()

      if (selection !== '') {
        this.$clipboard(selection)
      }
    })
  },
  methods: {
    ...mapActions('Stickers', ['edit', 'remove']),
    onDashboard (sticker) {
      // Флаг "в избранном" должен меняться только когда сервер пример изменения
      let onDashboard = sticker.onDashboard
      const prop = { onDashboard: onDashboard ^= true }
      const newSticker = { ...sticker, ...prop }

      this.edit(newSticker)
    },
    saveSticker (newSticker) {
      return this.edit(newSticker)
    },
    openStickerForm () {
      this.$modal.show(
          StickerForm,
          {
            sticker: this.sticker,
            saveSticker: this.saveSticker
          },
          {
            adaptive: true,
            width: '318px',
            height: 'auto'
          }
      )
    },
    deleteSticker (sticker) {
      if (confirm('Are you sure to delete the sticker now?')) {
        this.remove(sticker)
      }
    },
    convertColor (color) {
      let c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
        c = color.substring(1).split('')

        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }

        c = '0x' + c.join('')

        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.7)'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sticker {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  width: 100%;
  max-width: 1250px;
  height: 200px;
  color: #333;
  font-size: 18px;
  /*user-select: none;*/
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#82cff1', endColorstr='#38aeea', GradientType=0);
  backdrop-filter: blur(25px);
  background: rgba(255, 255, 255, 0.5);

  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }

  &__control {
    position: absolute;
    bottom: -9px;
    right: 5px;
    left: 5px;
    display: flex;
    font-size: 14px;

    &__edit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      background-color: #ffc46c;
      border: 1px solid #eee;
      padding: 0;
      border-radius: 50%;
      color: #333;
      line-height: 1;

      &:hover {
        cursor: pointer;
      }

      & i, & svg {
        width: 12px;
        height: 12px;
      }
    }

    &__star {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      background-color: #117bff;
      border: 1px solid #eee;
      padding: 0;
      margin-right: 5px;
      border-radius: 50%;
      color: #333;
      line-height: 1;

      &:hover {
        cursor: pointer;
      }

      & i, & svg {
        width: 12px;
        height: 12px;
      }

      &.active {
        color: #ffd745;
      }
    }

    &__remove {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      padding: 0;
      margin-left: auto;
      border: 1px solid #eee;
      border-radius: 50%;
      background: #ff184a;
      color: #fff;
      line-height: 1;

      &:hover {
        cursor: pointer;
      }

      & i, & svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__title {
    padding: 0 8px;
    max-width: 100%;
    height: 32px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    font-size: 1.25rem;
    line-height: 28px;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.-draggable &__title:hover {
    cursor: all-scroll;
  }

  &__body::v-deep {
    padding: 10px 8px;
    max-width: 100%;
    height: 168px;
    font-size: 1rem;
    color: inherit;
    overflow: auto;
    overflow-wrap: anywhere;

    a {
      color: inherit;
      filter: hue-rotate(115deg);

      &:hover {
        filter: hue-rotate(125deg);
      }
    }
  }
}

.control-group {
  display: flex;
}
</style>

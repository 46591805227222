<template>
  <div class="stickers">
    <h2>Stickers</h2>

    <div class="stickers-grid">
      <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

      <div class="stickers-grid__item" v-for="(sticker, index) in favoritedStickers" :key="index">
        <Sticker :sticker="sticker"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Sticker from './Sticker'
import Loading from 'vue-loading-overlay'

export default {
  name: 'DashboardStickers',
  components: {
    Sticker,
    Loading
  },
  mounted () {
    this.loadAll()
  },
  computed: {
    ...mapGetters('Stickers', ['favoritedStickers', 'isLoading']),
  },
  methods: {
    ...mapActions('Stickers', ['loadAll'])
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 0;
}
.stickers {
  max-width: 1570px;
  margin: 0 auto;
}

.stickers-grid {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  &__item {
    padding: 0 8px;
    margin-bottom: 20px;
    width: 316px;
  }
}
</style>

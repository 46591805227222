<template>
  <div class="bookmarks">
    <h2>Bookmarks</h2>

    <div class="bookmark-list">
      <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

      <div class="add-bookmark" @click="openModal()">
        <i class="fas fa-plus"></i>
      </div>

      <draggable v-model="bookmarks" group="bookmarks" class="planks">
        <div v-for="(bookmark) in bookmarks" :key="bookmark.id" class="planks__item">
          <Bookmark :bookmark="bookmark"/>
        </div>
      </draggable>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {v4 as uuidv4} from 'uuid'
import draggable from 'vuedraggable'
import Bookmark from './Bookmark'
import BookmarkForm from './BookmarkForm'
import DefaultBookmark from './Model/Bookmark'
import Loading from 'vue-loading-overlay'

export default {
  name: 'DashboardBookmarks',
  components: {
    Bookmark,
    Loading,
    draggable
  },
  data () {
    return {
      newCategoryTitle: '',
    }
  },
  mounted () {
    this.loadBookmarks()
  },
  computed: {
    ...mapGetters('Bookmarks', ['isLoading']),
    bookmarks: {
      get() {
        return this.$store.getters['Bookmarks/dashBookmarks']
      },
      set(value) {
        this.$store.dispatch('Bookmarks/reorderDashBookmarks', value)
      }
    }
  },
  methods: {
    ...mapActions('Bookmarks', ['loadBookmarks', 'addBookmark']),
    createBookmark (bookmark) {
      bookmark.id = uuidv4()
      bookmark.onDashboard = true

      return this.addBookmark(bookmark)
    },
    openModal () {
      const bookmark = { ...DefaultBookmark }

      this.$modal.show(
          BookmarkForm,
          {
            bookmark: bookmark,
            saveBookmark: this.createBookmark
          },
          {
            adaptive: true,
            width: '318px',
            height: 'auto'
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 0;
}

.bookmarks {
  max-width: 1580px;
  margin: 0 auto;
}

.groups {
  &__item {
    * {
      user-select: none;
    }
  }
}

.planks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 50px;

  &__item {
    margin: 0 15px 5px;
    max-width: 300px;
    user-select: none;
  }
}

.bookmark-list {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.add-bookmark {
  display: block;
  padding: 2px 8px;
  width: 34px;
  margin-right: 15px;
  background: rgba(255, 255, 255,.5);
  border: 2px solid rgba(255, 255, 255,.2);
  border-radius: 50%;
  color: #222;
  text-align: center;
  backdrop-filter: blur(25px);

  &:hover {
    border-color: rgba(100,200,255,.5);
    background: rgba(100,200,255,.5);
    cursor: pointer;
  }
}
</style>
